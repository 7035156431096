/**
 * Rounds the given `value` up to the nearest integer multiple of `interval`. If omited, `interval`
 * is set to 10.
 *
 * @example
 * ceiling(77)     // 80
 * ceiling(36, 6)  // 42
 * ceiling(492, 8) // 496
 */
export const ceiling = (value: number, interval = 10) =>
  (Math.floor(value / interval) + 1) * interval;
