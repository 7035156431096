import { useMemo, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { isNil } from 'lodash';

import { usePageRythm } from '~/hooks/misc';
import { ceiling } from '~/utilities/math.utility';

export interface AssetImageProps
  extends Omit<GatsbyImageProps, 'style' | 'image' | 'alt'> {
  image: Partial<Queries.ContentfulAsset>;
  aspectRatio?: number;
  alt?: string;
}

export const AssetImage = ({
  image,
  aspectRatio = 1 / 1,
  alt = 'cover image',
  ...passThroughProps
}: AssetImageProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // Calculate the height of our image so that it aligns properly with the page rythm.
  const pageRythm = usePageRythm();
  const dimensions = useSize(elementRef);
  const height = useMemo(() => {
    const width = dimensions?.width ?? 0;
    return ceiling(width * aspectRatio, pageRythm);
  }, [dimensions, aspectRatio, pageRythm]);

  if (isNil(image.gatsbyImageData)) {
    return <></>;
  }

  return (
    <Box ref={elementRef} height={height}>
      <GatsbyImage
        style={{ height: '100%' }}
        image={image.gatsbyImageData}
        alt={alt}
        {...passThroughProps}
      />
    </Box>
  );
};
