import { graphql } from 'gatsby';
import { List, ListItem, Text } from '@chakra-ui/react';

export interface ConcertListProps {
  concerts: Queries.ConcertFragment[];
  isRightAligned?: boolean;
}

export const ConcertList = ({
  concerts,
  isRightAligned = false,
}: ConcertListProps) => (
  <List spacing={3} textAlign={isRightAligned ? 'right' : 'left'}>
    {concerts.map(
      (concert, index) =>
        concert && (
          <ListItem key={`${index}_${concert.name}`}>
            <Text>
              <Text as="i">{concert.name}</Text>{' '}
              {concert.duration ? `(${concert.duration}')` : ''}
            </Text>
            {concert.description && <Text>{concert.description}</Text>}
            {(concert.year || concert.location) && (
              <Text>
                {concert.year} {concert.location}
              </Text>
            )}
          </ListItem>
        )
    )}
  </List>
);

export const query = graphql`
  fragment Concert on ContentfulConcert {
    name
    description
    year
    location
    duration
  }
`;
