import { Box, Flex } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';

import { AssetImage } from '~/components/asset-image';
import { ConcertList } from '~/components/concert-list';
import { Section } from '~/components/section';
import { StandardLayout } from '~/components/layout';
import { excludeNilItems } from '~/utilities/arrays.utility';

const ConcertsPage = ({ data }: PageProps<Queries.ConcertsPageQuery>) => {
  const person = data.contentfulPerson;

  return (
    <StandardLayout>
      <Section title="Competitions">
        <ConcertList concerts={excludeNilItems(person?.competitions ?? [])} />
      </Section>
      <Section title="Notable works include" isRightAligned>
        <ConcertList
          concerts={excludeNilItems(person?.notableConcerts ?? [])}
          isRightAligned
        />
      </Section>
      <Section title="Master concert compositions">
        <ConcertList
          concerts={excludeNilItems(person?.masterConcertCompositions ?? [])}
        />
      </Section>
      {data.contentfulAsset && (
        <Flex justify="flex-end">
          <Box width={{ sm: '100%', lg: '30.5rem' }}>
            <AssetImage image={data.contentfulAsset} aspectRatio={9 / 16} />
          </Box>
        </Flex>
      )}
    </StandardLayout>
  );
};

export default ConcertsPage;

export const pageQuery = graphql`
  query ConcertsPage {
    contentfulPerson(isPortfolioOwner: { eq: true }) {
      name
      competitions {
        ...Concert
      }
      notableConcerts {
        ...Concert
      }
      masterConcertCompositions {
        ...Concert
      }
    }

    contentfulAsset(
      metadata: {
        tags: {
          elemMatch: {
            contentful_id: { eq: "flavorImage", regex: "/concerts/" }
          }
        }
      }
    ) {
      title
      gatsbyImageData(placeholder: BLURRED)
    }
  }
`;
