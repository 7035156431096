import { useEffect, useState } from 'react';

const DEFAULT_PAGE_RYTHM = 8;

/**
 * Calculates the current page rythm.
 */
export const usePageRythm = () => {
  const [rythm, setRythm] = useState(DEFAULT_PAGE_RYTHM);

  // Calculate via a useEffect since we want to ensure it runs in the browser.
  useEffect(() => {
    const baseFontSize = window
      ?.getComputedStyle(document.body)
      .getPropertyValue('font-size');

    setRythm(parseInt(baseFontSize, 10) / 2);
  }, [setRythm]);

  return rythm;
};
